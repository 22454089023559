import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Adobe Advanced Technology Lab Visual Assets
I led a redesign for Adobe Advanced Technology Lab internal and external look. Create new visual assets, website, newsletters and flyers.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/02/TP.png",
        "alt": "aat_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/02/research.png",
        "alt": "aat_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/02/library.png",
        "alt": "aat_3"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/02/about.png",
        "alt": "aat_4"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/01-home.png",
        "alt": "aat_5"
      }}></img></p>
    <p>{`PlayPrev|Next1 of 5`}</p>
    <p><a parentName="p" {...{
        "href": "http://raschin.com/blog/wp-content/uploads/2011/02/det3.jpg"
      }}>{`http://raschin.com/blog/wp-content/uploads/2011/02/det3.jpg`}</a></p>
    <p>{`Internship Flyer`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/b1.jpg",
        "alt": "flyer_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/b2.jpg",
        "alt": "flyer_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/b3.jpg",
        "alt": "flyer_3"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/b4.jpg",
        "alt": "flyer_4"
      }}></img></p>
    <p>{`PlayPrev|Next1 of 4`}</p>
    <p>{`Internal Newsletters`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/news1-535x1024.jpg",
        "alt": "nl_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/news2-447x1024.png",
        "alt": "nl_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2011/01/news3-689x1024.png",
        "alt": "nl_3"
      }}></img></p>
    <p>{`PlayPrev|Next1 of 3`}</p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      